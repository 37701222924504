/**
 * buttonProps.tsx
 *
 * Provides the props needed to make any element a ARIA compatible button
 *
 * @author Sam Boles <sam@boles.eu>
 * @copyright Kunstmatrix GbR
 *
 */
import React from 'react';

interface ButtonProps {
  onClick: () => void;
  onKeyPress: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  role: 'button';
  tabIndex: 0;
}

/**
 * buttonProps
 *
 * Provides a spreadable set of props for a button that fulfill the base ARIA requirements.
 *
 * @example
 * <div {...buttonProps(() => alert("You clicked the button!!"))}>
 *  Click me!
 * </div>
 *
 * @param {()=>void} onClick - The function to run when the button is clicked.
 *
 */
const buttonProps = (onClick: Function): ButtonProps => ({
  onClick: () => {
    onClick();
  },
  onKeyPress: (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onClick();
    }
  },
  role: 'button',
  tabIndex: 0,
});

export default buttonProps;
