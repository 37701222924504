/**
 * App.tsx
 *
 * @copyright Kunstmatrix GbR
 * @author Sam Boles <sam@boles.eu>
 */
import React from 'react';

import './App.css';
import VideoUploader from './components/VideoUploader';

function App() {
  return (
    <div className="App">
      <h2>Upload Video</h2>
      <VideoUploader />
    </div>
  );
}

export default App;
