/**
 * VideoUploader.tsx
 *
 * Provides a component for uploading Videos to be converted.
 *
 * @copyright Kunstmatrix GbR
 * @author Sam Boles <sam@boles.eu>
 */
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import buttonProps from '../buttonProps';

// const UPLOAD_URL = 'https://helpers.kunstmatrix.com/video/convert/';
const UPLOAD_URL = 'https://videoconvert.kunstmatrix.com/convert/';

async function uploadVideo(
  setStatusURL: (url: string) => void,
  setUploadProgress: (progress: number) => void,
) {
  const videoInput = document.getElementById(
    'video-upload-input',
  ) as HTMLInputElement | null;
  if (videoInput === null || videoInput.files === null) {
    console.log('No file selected');
    return;
  }
  const video = videoInput.files[0];
  const formData = new FormData();
  formData.append('video', video);

  try {
    const { data } = await axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      url: UPLOAD_URL,
      onUploadProgress: (ev: ProgressEvent) => {
        const progress = (ev.loaded / ev.total) * 100;
        setUploadProgress(progress);
      },
    });

    setStatusURL(data.statusURL);
  } catch (e) {
    console.log(e);
  }
}

export default function VideoUploader() {
  const [statusURL, setStatusURL] = useState('');
  const [videoURL, setVideoURL] = useState('');
  const [stage, setStage] = useState('');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get(statusURL);
      console.log(data);
      if (data.progress) {
        setProgress(data.progress);
      }
      if (data.videoURL && data.videoURL !== '') {
        setVideoURL(data.videoURL);
        setProgress(100);
        setStage('');
      } else {
        setTimeout(getData, 500);
      }
    };
    if (statusURL !== '') {
      getData();
      setStage('transcoding');
      setProgress(0);
    }
  }, [statusURL, setVideoURL, setStage, setProgress]);
  return (
    <div>
      <form className="video-upload-form">
        <label htmlFor="video" className="video-upload-label">
          <input type="file" name="video" id="video-upload-input" />
          <span>Choose video to upload</span>
        </label>
        {stage === '' && (
          <div
            className="upload-video-button"
            {...buttonProps(() => {
              setStage('uploading');
              uploadVideo(setStatusURL, setProgress);
            })}
          >
            Upload Video
          </div>
        )}
      </form>
      <div>
        {stage !== '' && (
          <div>
            <h3>{stage}</h3>
            <span>Progress {Math.round(progress)}%</span>
          </div>
        )}
        {videoURL !== '' && (
          <>
            <video controls width="250">
              <source src={videoURL} type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
            <a href={videoURL} download="video.mp4">
              Download video
            </a>
          </>
        )}
      </div>
    </div>
  );
}
